<template>
    <section>
        <div class="row mx-0">
            <div class="col-12 f-17 mt-3">
                Seleccione el orden en el que se deberán desarrollar los temas <br /> 
            </div>
        </div>
        <div class="row mx-0 mb-4">
            <div class="col-auto mt-4">
                <div class="bg-white text-center px-4 br-12 py-2 shadow f-18 cr-pointer" @click="CrearTema">
                    Crear Tema
                </div>
            </div>
        </div>
        <draggable
        :list="temas"
        class="list-group"
        handle=".handle"
        item-key="name"
        @change="cambioPosicion"
        >
            <div v-for="(t, index) in temas" :key="index" class="row mx-0 a-center mt-2 pl-3">
                <div class="col-auto px-0">
                    <i class="icon-drag f-20 cr-pointer text-general handle" />
                </div>
                <div class="col tres-puntos f-15 text-gris">
                    {{ t.nombre }}
                </div>
                <div class="col-2 px-0 d-middle f-15 text-gris">
                    {{ t.cant_items }} archivos
                </div>
                <div class="col-2 px-0">
                    <i class="icon-pencil f-20 text-general cr-pointer" @click="$router.push({name:'mis-cursos.edita.tema',params:{id_tema:t.id}}) " />
                    <i class="icon-cancel f-20 text-general cr-pointer" @click="modalEliminarTema(t.id)" />
                </div>
            </div>
        </draggable>
        <div v-if="temas.length === 0">
            <sin-datos mensaje="Aún no se han creado temas." />
        </div>

        <modalCrearTema ref="modalCrearTema" @update="listarTemas" />
        <modal-eliminar 
        ref="EliminarTemaModal" 
        titulo="¿Esta Seguro de eliminar este Tema?" 
        alerta="Los datos de este Tema y la informacion no podran ser recuperados"
        eliminar
        @eliminar="eliminarTema"
        />
    </section>
</template>

<script>
import Cursos from '~/services/cursos/gestionar'
export default {
    components: {
        modalCrearTema: () => import('../../partials/modalCrearTema')
    },
    data(){
        return {
            temas: []

        }
    },
    computed:{
        id_curso(){
            return Number(this.$route.params.id_curso)
        }
    },
    mounted(){
        this.listarTemas()
    },
    methods:{
        async listarTemas(){
            try {
                
                const {data} = await Cursos.listarTemas(this.id_curso)
                this.temas = data.data.temas

            } catch (error){
                this.error_catch(error)
            }
        },
        CrearTema(){
            this.$refs.modalCrearTema.toggle()
        },
        async cambioPosicion({moved}){
            try {
                let model = {
                    nuevaPosicion:moved.newIndex + 1,
                    antiguaPosicion:moved.oldIndex + 1
                }
                
                const {data} = await Cursos.updatePosicionTema(this.id_curso,model)
                this.notify('Éxito, Posición Actualizada','success')

            } catch (error){
                this.listarTemas()
                this.error_catch(error)  
            }
        },
        async eliminarTema(){
            try {
                
                await Cursos.eliminarTema(this.id_tema_eliminar)
                this.notify('Eliminado, Registro removido correctamente','success')
                this.$refs.EliminarTemaModal.toggle();
                this.id_tema_eliminar = null
                this.listarTemas()


            } catch (error){
                this.error_catch(error) 
            }
        },
        modalEliminarTema(id_tema){
            this.id_tema_eliminar = id_tema
            this.$refs.EliminarTemaModal.toggle();
        },
    }
}
</script>

<style lang="scss" scoped>
</style>